/**
 * @file Contains all client-side code related to user tracking.
 */

import { MiaPlaza } from "@/Reinforced.Typings";
import { OneTrustApi, OneTrustGroupId } from "./onetrust";

/**
 * An interface for the `window` object that includes properties related to user tracking and consent management.
 *
 * @property {MiaPlaza.Control.Tracking.ITrackingConfig} [trackingConfig]
 *	tracking configuration object injected from `wwwroot/Content/Tracking/TrackingConfig.cs`.
 *	Contains settings that control how tracking is performed on the site.
 *
 * @property {OneTrustApi} [OneTrust]
 *	instance of the OneTrust API, which is used to manage user consent and privacy settings.
 *
 * @property {string} [_ubad]
 *	string representing the Unbounce domain used to load Unbounce tracking scripts.
 *
 * @property {Array<any>} [_ubaq]
 *	command queue array for Unbounce tracking events.
 *
 * @property {object} OptanonWrapper
 *   A function callback provided by us, which is called by OneTrust. 
 *   It is executed on new page loads and whenever user consent changes, 
 */
interface WindowWithTracking extends Window {
	trackingConfig?: MiaPlaza.Control.Tracking.ITrackingConfig;
	OneTrust?: OneTrustApi;
	_ubad?: string;
	_ubaq?: Array<Array<string>>;
	OptanonWrapper?: () => void;
}

/**
 * Initializes and sets up Unbounce tracking script if all conditions are met
 * @param window The window object with tracking configuration
 */
function initializeUnbounceTracking(window: WindowWithTracking): void {
	if (!window.trackingConfig?.UnbounceDomain || !window.trackingConfig.SendUnbounceTrackingEvent) {
		return;
	}
	window._ubad = window.trackingConfig.UnbounceDomain;
	window._ubaq = (window._ubaq || []);
	window._ubaq.push(['trackGoal']);
	const OneTrust: any = window.OneTrust;
	OneTrust.InsertScript(
		`//${window._ubad}/_ub/static/ets/t.js`,
		'head',
		undefined,
		undefined,
		OneTrustGroupId.Targeting
	);

}

/**
 * Inserts the tracking scripts into the DOM if tracking is enabled.
 */
export function startTracking(window: WindowWithTracking): void {
	if (window.trackingConfig?.IsTrackingEnabled !== true) {
		return;
	}

	window.OptanonWrapper = function () {
		initializeUnbounceTracking(window);
	};

	// NOTE: to do: migrate all other tracking pixels here.
}
