var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mia-SiteContactTypeForm panel panel-default" },
    [
      _c("div", { staticClass: "panel-heading" }, [
        _c("h3", { staticClass: "panel-title" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.$tr("PopupContactTitle")) + "\n\t\t")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-body" },
        [
          _vm.isChild
            ? [
                _vm.isLoggedIn
                  ? [
                      _c("h1", {
                        directives: [
                          {
                            name: "tr",
                            rawName: "v-tr",
                            value: "PopupContact_loggedIn_children_title",
                            expression: "'PopupContact_loggedIn_children_title'"
                          }
                        ]
                      }),
                      _vm._v(" "),
                      _c("p", {
                        directives: [
                          {
                            name: "tr",
                            rawName: "v-tr",
                            value: {
                              key: "PopupContact_loggedIn_children",
                              chatSupportLink: _vm.chatSupportLink
                            },
                            expression:
                              "{ key: 'PopupContact_loggedIn_children', chatSupportLink: chatSupportLink }"
                          }
                        ],
                        staticClass: "mia-SiteContactTypeForm-paragraph"
                      })
                    ]
                  : [
                      _c("h1", {
                        directives: [
                          {
                            name: "tr",
                            rawName: "v-tr",
                            value: "PopupContact_help_title",
                            expression: "'PopupContact_help_title'"
                          }
                        ]
                      }),
                      _vm._v(" "),
                      _c("p", {
                        directives: [
                          {
                            name: "tr",
                            rawName: "v-tr",
                            value: "PopupContact_children",
                            expression: "'PopupContact_children'"
                          }
                        ],
                        staticClass: "mia-SiteContactTypeForm-paragraph"
                      })
                    ]
              ]
            : [
                _c("h1", {
                  directives: [
                    {
                      name: "tr",
                      rawName: "v-tr",
                      value: "PopupContact_help_title",
                      expression: "'PopupContact_help_title'"
                    }
                  ],
                  staticClass: "mia-SiteContactTypeForm-title"
                }),
                _vm._v(" "),
                _c("p", {
                  directives: [
                    {
                      name: "tr",
                      rawName: "v-tr",
                      value: _vm.isLoggedIn
                        ? "PopupContact_loggedIn_parents"
                        : "PopupContact_parents",
                      expression:
                        "isLoggedIn ? 'PopupContact_loggedIn_parents' : 'PopupContact_parents'"
                    }
                  ],
                  staticClass: "mia-SiteContactTypeForm-paragraph"
                }),
                _vm._v(" "),
                _c("div", {
                  ref: "typeformEmbed",
                  attrs: { "data-tf-live": "01JKE5GNDRPRGEFJYWQF35ZEKP" }
                })
              ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }