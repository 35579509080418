var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c("logo", {
        attrs: {
          development: _vm.development,
          maintenance: _vm.maintenance,
          "is-active-mohs-student": _vm.isActiveMohsStudent,
          "is-plus-student": _vm.isPlusStudent
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mia-headerNavigationContainer" },
        [
          _c("funds-module-registration", {
            attrs: {
              amount: _vm.profileData.FundsAmount,
              "feedback-sound-on": _vm.feedbackSoundOn
            }
          }),
          _vm._v(" "),
          _c("funds-hub-connection", {
            attrs: { "feedback-sound-on": _vm.feedbackSoundOn }
          }),
          _vm._v(" "),
          _vm.videos.length > 0
            ? _c("tutorial-videos-dropdown", {
                staticClass: "mia-TutorialVideosDropdown hidden-xs hidden-sm",
                attrs: { videos: _vm.videos }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("funds", {
            staticClass: "hidden-xs hidden-sm",
            class: { disabled: _vm.isLearningRequired },
            attrs: { "funds-page-url": _vm.profileData.FundsPageUrl }
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "mia-profileLink btn",
              class: {
                active: _vm.profilePage,
                disabled: _vm.isLearningRequired
              },
              attrs: { href: _vm.profileData.ProfileUrl }
            },
            [
              _c("div", { staticClass: "mia-ProfileImage" }, [
                _c(
                  "div",
                  { staticClass: "mia-circleContainer" },
                  [
                    _c("responsive-image", {
                      attrs: { from: _vm.profileData.Avatar }
                    }),
                    _vm._v(" "),
                    _vm.profileData.NewGuestBookEntries
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "badge badge-pill mia-round-badge-guestBookEntries",
                            attrs: {
                              title: _vm.$tr("profileunseenguestbookentries")
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.profileData.NewGuestBookEntries) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "mia-profileLinkName" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.profileData.Name) + "\n\t\t\t")
              ])
            ]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "mia-linkList hidden-xs hidden-sm" }, [
            _c("li", [
              _c("a", {
                directives: [
                  {
                    name: "tr",
                    rawName: "v-tr",
                    value: "parentsportal",
                    expression: "'parentsportal'"
                  }
                ],
                staticClass: "btn btn-link mia-btn-dark",
                attrs: { href: _vm.parentsPortalLink }
              })
            ]),
            _vm._v(" "),
            _c("li", [
              _c("a", {
                directives: [
                  {
                    name: "tr",
                    rawName: "v-tr",
                    value: "logout",
                    expression: "'logout'"
                  }
                ],
                staticClass: "btn btn-link mia-btn-warning",
                attrs: { href: _vm.logoutLink }
              })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }