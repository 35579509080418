/**
 * @file Contains interfaces for OneTrust API provided by the OneTrust script.
 * @see https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94
 */

/**
 * Category identifiers used by OneTrust for various types of cookies.
 */
export const enum OneTrustGroupId {
	StrictlyNecessary = 'C0001',
	Performance = 'C0002',
	Functionality = 'C0003',
	Targeting = 'C0004',
	SocialMedia = 'C0005',
}

/**
 * Options for inserting tracking scripts via the OneTrust API.
 */
export interface OneTrustInsertOptions {
	/** Delete all parent container content before inserting the element. */
	deleteSelectorContent?: boolean;

	/** Shows parent container element after inserting the element. */
	makeSelectorVisible?: boolean;

	/** A list of html element IDs to show after inserting the element. */
	makeElementsVisible: string[];

	/** A list of html element IDs to delete after inserting the element. */
	deleteElements: string[];
}

/**
 * API provided by OneTrust in the `window.OneTrust` object.
 */
export interface OneTrustApi {
	/**
	 * Inserts a raw HTML snippet into the DOM if the provided group ID is enabled.
	 * 
	 * @param html the raw HTML snippet to insert.
	 * @param parentId the ID of the HTML element to insert the snippet into.
	 * @param callback a function to call after the snippet has been inserted.
	 * @param options additional options for inserting the snippet.
	 * @param groupId OneTrust group ID to check if the snippet should be inserted.
	 * @returns 
	 */
	InsertHtml: (html: string, parentId: string, callback?: () => void, options?: OneTrustInsertOptions, groupId?: OneTrustGroupId) => void;

	/**
	 * Inserts a `<script>` element into the DOM if the provided group ID is enabled.
	 * 
	 * @param url the URL of the script.
	 * @param parentId the ID of the HTML element to insert the snippet into.
	 * @param callback a function to call after the snippet has been inserted.
	 * @param options additional options for inserting the snippet.
	 * @param groupId OneTrust group ID to check if the snippet should be inserted.
	 * @returns 
	 */
	InsertScript: (url: string, parentId: string, callback?: () => void, options?: OneTrustInsertOptions, groupId?: OneTrustGroupId) => void;
}
