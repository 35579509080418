/**
 * Helper functions to trigger prompts client-side.
 */

import { MiaPlaza } from "../../../Reinforced.Typings";
import I18Next from "../I18Next";
import Notifier from "./Shared/Notifier";

type MaybeTextResource = string | Promise<string>;

interface IChoice<T> {
	label: MaybeTextResource;
	value: T;
}

export interface IPrompt extends MiaPlaza.Control.Vue.Notifications.Shared.INotification {
	/**
	 * The buttons to display. Each choice has a label and a callback that gets called when it is clicked.
	 */
	choices: Array<IChoice<() => void>>;
	/**
	 * The main message to display in the prompt.
	 */
	message: MaybeTextResource;
}

/**
 * Show a prompt with a close button. The promise resolves when that button is clicked.
 */
export function prompt(message: MaybeTextResource): Promise<unknown>;
/**
 * Show a prompt with a button for each element of choices. The returned promise resolves
 * when any of the buttons is clicked.
 */
export function prompt<T>(message: MaybeTextResource, choices: Array<IChoice<T>>): Promise<T>;
export function prompt<T>(message: MaybeTextResource, choices?: Array<IChoice<T>>): Promise<T> {
	if (choices == null || choices.length === 0) {
		choices = [{
			label: I18Next.tr("Prompt_Close"),
			value: undefined as unknown as T,
		}];
	}
	return new Promise<T>(
		(resolve) => {
			Notifier.show<IPrompt>({
				Template: "Prompt",
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				choices: choices!.map((choice) => ({
					label: choice.label,
					value: () => resolve(choice.value),
				})),
				message,
			});
		},
	);
}

/**
 * Show an Ok/Cancel dialog.
 * @returns a promise that resolves to true when Ok has been selected, otherwise to false.
 */
export function okCancel(message: MaybeTextResource): Promise<boolean> {
	return prompt(message, [
		{ label: I18Next.tr("OkCancel_Ok"), value: true },
		{ label: I18Next.tr("OkCancel_Cancel"), value: false },
	]);
}

/**
 * Show a Yes/No dialog.
 * @returns a promise that resolves to the selected option.
 */
export function yesNo(message: MaybeTextResource): Promise<boolean> {
	return prompt(message, [
		{ label: I18Next.tr("YesNo_Yes"), value: true },
		{ label: I18Next.tr("YesNo_No"), value: false },
	]);
}

export function errorPrompt(message: string): void {
	Notifier.show<MiaPlaza.Control.Vue.Notifications.ISessionMessage>({
		Message: message,
		Template: "SessionMessage",
		Type: MiaPlaza.Control.Vue.Notifications.SessionMessageType.Error,
	});
}

export function warningPrompt(message: string): void {
	Notifier.show<MiaPlaza.Control.Vue.Notifications.ISessionMessage>({
		Message: message,
		Template: "SessionMessage",
		Type: MiaPlaza.Control.Vue.Notifications.SessionMessageType.Warning,
	});
}

export function successPrompt(message: string): void {
	Notifier.show<MiaPlaza.Control.Vue.Notifications.ISessionMessage>({
		Message: message,
		Template: "SessionMessage",
		Type: MiaPlaza.Control.Vue.Notifications.SessionMessageType.Success,
	});
}

export function infoPrompt(message: string): void {
	Notifier.show<MiaPlaza.Control.Vue.Notifications.ISessionMessage>({
		Message: message,
		Template: "SessionMessage",
		Type: MiaPlaza.Control.Vue.Notifications.SessionMessageType.Info,
	});
}
