
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Player from '@vimeo/player'

@Component
export default class VimeoPlayer extends Vue {
	@Prop({ required: true }) protected videoSrc!: string;
	@Prop({ required: false, default: false}) protected enableGoogleTracking!: boolean;
	@Prop({ required: false, default: false}) protected autoPlay!: boolean ;
	@Prop({ required: false, default: null}) protected volume!: number | null;
	@Prop({ required: false, default: null}) protected textTrack!: string | null ;
	@Prop({ required: false, default: null}) protected time!: number | null;
	
	protected player!: Player;

	// The time stamp we reported most recently in a update:time event.
	private timeupdate: number | null = null;

	async mounted(): Promise<void> {
		this.player = new Player(this.$refs.vimeoPlayer as HTMLIFrameElement);

		await this.onTimeChange();
		await this.onVolumeChange();
		await this.onTextTrackChange();

		this.player.on('timeupdate', (data: any) => {
			this.timeupdate = data.seconds;
			this.$emit("update:time", this.timeupdate);
		})
		this.player.on('ended', () => this.$emit("ended"));
		this.player.on('volumechange', (event: any) => this.$emit('update:volume', event.volume));

		if (this.autoPlay){
			await this.play();
		}
	}

	// Implementation note: This is not a good pattern. Instead there should be a
	// reactive .syncable "state" prop that controls whether the player is
	// currently paused or playing. However, we need this interface to support
	// some legacy code paths that we did not want to risk to break in the latest
	// round of changes here.
	public async play(): Promise<void> {
		await this.player.ready();
		await this.player.play();
		if (this.enableGoogleTracking && typeof gtag === 'function') {
			// eslint-disable-next-line no-undef
			gtag('event', 'play', {
				event_category: 'video',
				event_label: this.videoSrc
			});
		}
	}

	public async pause(): Promise<void> {
		await this.player.ready();
		await this.player.pause();
	}

	// Implementation note: This method exists to support some legacy code paths.
	// It should not be used anymore. Instead the "time" should be set directly.
	public async setVideoProgress(seconds: number): Promise<void> {
		await this.player.ready();
		await this.player.setCurrentTime(seconds);
	}

	@Watch("time")
	private async onTimeChange() {
		await this.player.ready();
		if (this.time == null) {
			return;
		}
		if (this.time !== this.timeupdate) {
			this.timeupdate = this.time;
			// We must not set the time to exactly 0, see
			// https://github.com/vimeo/player.js/issues/178 (which is closed but not
			// fixed as of early 2025.)
			await this.player.setCurrentTime(Math.max(this.time, Number.EPSILON));
		}
	}

	@Watch("volume")
	private async onVolumeChange() {
		await this.player.ready();
		if(this.volume == null) {
			return;
		}
		await this.player.setVolume(this.volume);
	}

	@Watch("textTrack")
	private async onTextTrackChange() {
		await this.player.ready();
		if(this.textTrack == null) {
			return;
		}
		await this.player.enableTextTrack(this.textTrack);
	}
}
