



























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SiteContactTypeForm extends Vue {
	@Prop({ required: true, type: Boolean }) private isLoggedIn!: boolean;
	@Prop({ required: true, type: Boolean }) private isChild!: boolean;
	@Prop({ required: false, type: String, default: null }) private chatSupportLink!: string | null;

	mounted(): void {
		if (this.$refs.typeformEmbed) {
			const script: HTMLScriptElement = document.createElement('script');
			script.src = "//embed.typeform.com/next/embed.js";
			script.async = false;
			(this.$refs.typeformEmbed as HTMLElement).parentElement?.appendChild(script);
		}
	}
}
