var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("buffered-list-items", {
    attrs: { adapter: _vm.listAdapter },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var buffered = ref.buffered
          return [
            _c(
              _vm.effectiveComponent,
              _vm._b(
                {
                  ref: "vueList",
                  tag: "component",
                  attrs: {
                    "buffered-items": buffered,
                    "conversion-adapter": _vm.conversionAdapter,
                    template: _vm.template
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function(_, slot) {
                        return {
                          key: slot,
                          fn: function(scope) {
                            return [_vm._t(slot, null, null, scope)]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                "component",
                _vm.$attrs,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }